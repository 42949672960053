const rootPath = "/api/v1";
const v2RootPath = "/api/v2";
const rootAuthPath = "/users";

interface IApiPath {
  app: {
    getConfig: string;
    getAnnouncement: string;
  };
  promotion: {
    remove: string;
  };
  auth: {
    me: string;
    checkBankAccount: string;
    checkBankAccountLaos: string;
    register: string;
    forgotPassword: string;
    resetPassword: string;
    platformWallets: string;
  };
  bank: {
    bankList: string;
    qrCodeKbank: string;
    qrCodeVizplay: string;
  };
  game: {
    getProvider: string;
  };
  wheel: {
    wheelList: string;
    wheelSpin: (type: string) => string;
  };
  mysteryBox: {
    mysteryBoxList: string;
    mysteryBoxOpen: string;
  };
}

export const apiPath: IApiPath = {
  app: {
    getConfig: `${rootPath}/config/get-all`,
    getAnnouncement: `${rootPath}/sliders/popup`,
  },
  promotion: {
    remove: `${rootPath}/promotion/remove`,
  },
  auth: {
    me: `${rootPath}/${rootAuthPath}/me`,
    checkBankAccount: `${rootPath}/${rootAuthPath}/checkbankaccount`,
    checkBankAccountLaos: `${rootPath}/${rootAuthPath}/checkbankaccountlaos`,
    register: `${rootPath}/${rootAuthPath}/registeruser`,
    forgotPassword: `${rootPath}/${rootAuthPath}/forget-password`,
    resetPassword: `${rootPath}/${rootAuthPath}/reset-password`,
    platformWallets: `${rootPath}/${rootAuthPath}/platform-wallets`,
  },
  bank: {
    bankList: `${rootPath}/banks/getBankDeposit`,
    qrCodeKbank: `${rootPath}/users/qrcode-kbank`,
    qrCodeVizplay: `${rootPath}/users/qrcode-vizpay`,
  },
  game: {
    getProvider: `${v2RootPath}/users/provider`,
  },
  wheel: {
    wheelList: `${rootPath}/wheel/all`,
    wheelSpin: (type: string) => `${rootPath}/wheel/spin?type=${type}`,
  },
  mysteryBox: {
    mysteryBoxList: `${rootPath}/mystery-box/all`,
    mysteryBoxOpen: `${rootPath}/mystery-box/random`,
  },
};
