import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";

const useConfig = () => {
  const [config] = useConfigStore((state) => [state.config], shallow);
  return {
    logo: config?.logo,
    turnstileKey: config?.turnstile_key,
  };
};

export default useConfig;
