"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button, Grid, Paper, StepContent, TextField } from "@mui/material";
import FormVerifyOtp from "./FormVerifyOtp";
import { shallow } from "zustand/shallow";
import FormVerifyBank from "./FormVerifyBank";
import FormVerifyIdentify from "./FormVerifyIdentify";
import { useSignUpStore } from "@/stores/signUpStore";
import { useSearchParams } from "next/navigation";

const stepContents = [FormVerifyBank, FormVerifyIdentify];

export default function StepperContent() {
  const searchParams = useSearchParams();
  const ref = searchParams.get("ref");
  const [step] = useSignUpStore((state) => [state.step], shallow);
  const Content = stepContents[step];

  React.useEffect(() => {
    if (ref) {
      localStorage.setItem("ref", ref);
    }
  }, [ref]);

  return <Content />;
}
