"use client";

import moment from "moment";
import { apiPath } from "@/configs/apiPath";
import { fetcherNonAuth } from "@/libs/utils";
import { Announcement, FetchResponse } from "@/@types/announcement";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const localStoragePrefix = "announcement_again_at";

export default function useAnnouncement(): Result {
  const [open, setOpen] = useState(false);

  const { data, error, isLoading } = useQuery<FetchResponse>({
    queryKey: ["announcements"],
    queryFn: () => fetcherNonAuth(apiPath.app.getAnnouncement),
  });

  const announcements = data?.data || [];

  useEffect(() => {
    const openTime = localStorage.getItem(localStoragePrefix) || moment();
    const ms = moment().diff(moment(openTime, "DD/MM/YYYY HH:mm:ss"));
    const duration = moment.duration(ms);
    const diffMinutes = duration.asMinutes();
    if (data!?.data?.length > 0 && diffMinutes >= 0) {
      setOpen(true);
    }
  }, [data]);

  const close = () => {
    const nextOpen = moment().add(1, "minute").format("DD/MM/YYYY HH:mm:ss");
    localStorage.setItem(localStoragePrefix, nextOpen);
    setOpen(false);
  };

  return {
    announcements,
    loading: isLoading,
    error,
    open,
    close,
  };
}

type Result = {
  announcements: Announcement[];
  loading: boolean;
  error: Error | null;
  open: boolean;
  close: () => void;
};
