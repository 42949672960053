type AuthConfig = {
  meEndpoint: string;
  registerEndpoint: string;
  loginEndpoint: string;
  logoutEndpoint: string;
  storageTokenKeyName: string;
  storageUserDataKeyName: string;
  storageAnnouncementAgainAt: string;
  onTokenExpiration: string;
};

const rootPath = "/api/v1/users";

const authConfig: AuthConfig = {
  meEndpoint: `${rootPath}/me`,
  registerEndpoint: `${rootPath}/register`,
  loginEndpoint: `${rootPath}/login`,
  logoutEndpoint: `${rootPath}/logout`,
  storageTokenKeyName: "accessToken",
  storageUserDataKeyName: "userData",
  storageAnnouncementAgainAt: "announcement_again_at",
  onTokenExpiration: "refreshToken",
};

export default authConfig;
