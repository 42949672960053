import client from "@/libs/http";
import { AxiosInstance } from "axios";

class UFA {
  client: AxiosInstance;

  constructor() {
    this.client = client;
  }

  public async getBalance(): Promise<any> {
    return this.client.get(
      `${process.env.NEXT_PUBLIC_APP_API_LAUNCH_GAME}/api/v1/users/ufa-info`
    );
  }
}

export const ufa = new UFA();
