import { apiPath } from "@/configs/apiPath";
import authConfig from "@/configs/auth";
import { getApiKeyHeaders } from "@/libs/utils";
import { User } from "@/@types/user";
import { ApiKeyHeaders } from "@/@types/util";
import { redirect } from "next/navigation";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";
import { ufa } from "@/services/ufa";

type State = {
  accessToken: string | null;
  user: User | null;
  loading: false | true;
  errMessage?: string;
};

type Actions = {
  setUser: (user: any) => void;
  updateBalance: (
    prop: "betflix_balance" | "ufa_balance" | "balance",
    balance: number
  ) => void;
  setLoading: (loading: boolean) => void;
  init: (user: User | null) => Promise<void>;
  login: (username: string, password: string) => Promise<void>;
  setErrMessage?: (errMessage: string) => void;
  getUfaBalance: () => Promise<void>;
  logout: () => Promise<void>;
};

export const useAuthStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      accessToken: null,
      user: null,
      loading: false,
      updateBalance: (prop, balance) => {
        set((state) => {
          if (state.user && prop === "betflix_balance") {
            state.user["betflix_balance"] = balance;
          } else if (state.user && prop === "ufa_balance") {
            state.user["ufa_balance"] = balance;
          } else if (state.user && prop === "balance") {
            state.user["balance"] = balance;
          }
        });
      },
      setUser: (user) => set((state) => ({ user })),
      setLoading: (loading) => set((state) => ({ loading })),
      init: async (user) => {
        set((state) => ({ loading: true }));
        if (user) {
          set((state) => ({ user }));
          set((state) => ({ loading: false }));
        } else {
          try {
            const accessToken = window.localStorage.getItem(
              authConfig.storageTokenKeyName
            );
            if (accessToken) {
              const xApiKeyHeaders: ApiKeyHeaders = getApiKeyHeaders();
              const response = await fetch(authConfig.meEndpoint, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  agent: xApiKeyHeaders.agent,
                  time: xApiKeyHeaders.time.toString(),
                  "X-Api-Key": xApiKeyHeaders["X-Api-Key"],
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              if (!response.ok) {
                window.localStorage.removeItem(
                  authConfig.storageUserDataKeyName
                );
                window.localStorage.removeItem(authConfig.storageTokenKeyName);
                set((state) => ({ user: null, accessToken: null }));
              } else {
                const data = await response.json();
                const user = data.data;
                window.localStorage.setItem(
                  authConfig.storageUserDataKeyName,
                  JSON.stringify(user)
                );
                set((state) => ({ user }));
              }
            }
          } finally {
            set((state) => ({ loading: false }));
          }
        }
      },
      login: async (username, password) => {
        set((state) => ({ loading: true }));
        try {
          const xApiKeyHeaders: ApiKeyHeaders = getApiKeyHeaders();
          const response = await fetch(authConfig.loginEndpoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              agent: xApiKeyHeaders.agent,
              time: xApiKeyHeaders.time.toString(),
              "X-Api-Key": xApiKeyHeaders["X-Api-Key"],
            },
            body: JSON.stringify({ username, password, remember: false }),
          });
          const data = await response.json();
          if (data.data) {
            const accessToken = data.data.token;
            window.localStorage.setItem(
              authConfig.storageTokenKeyName,
              accessToken
            );
            const fetchMe = await fetch(apiPath.auth.me, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                agent: xApiKeyHeaders.agent,
                time: xApiKeyHeaders.time.toString(),
                "X-Api-Key": xApiKeyHeaders["X-Api-Key"],
              },
            });
            const me = await fetchMe.json();
            window.localStorage.setItem(
              authConfig.storageUserDataKeyName,
              JSON.stringify(me.data)
            );
            window.document.cookie = `${authConfig.storageTokenKeyName}=${accessToken}`;
            set((state) => ({
              user: me.data,
              accessToken,
            }));
            return data.data;
          } else {
            set((state) => ({ errMessage: data.message, loading: false }));
            return { errMessage: data.msg };
          }
        } finally {
          set((state) => ({ loading: false }));
        }
      },
      getUfaBalance: async () => {
        ufa
          .getBalance()
          .then(({ data }) => {
            const {
              status,
              data: { ufa_current_credit },
            } = data;
            if (status === true) {
              set((state) => {
                if (state.user) {
                  state.user["ufa_balance"] = ufa_current_credit;
                }
              });
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      },
      logout: async () => {
        set((state) => ({ loading: true }));
        try {
          // remove token
          window.localStorage.removeItem(authConfig.storageTokenKeyName);
          // remove cookie
          window.document.cookie = `${authConfig.storageTokenKeyName}=`;
          // reset store
          set((state) => ({ user: null, accessToken: null }));
        } finally {
          set((state) => ({ loading: false }));
        }
      },
    }))
  ),
  Object.is
);

export const authGuard = () => {
  if (!useAuthStore.getState().user) {
    redirect("/login");
  }
};

export const getUser = () => {
  return useAuthStore.getState().user;
};
