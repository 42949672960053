import * as React from "react";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import { useAppModalStore } from "@/stores/appModalStore";
import { removePromotion } from "@/services/userService";
import { enqueueSnackbar } from "notistack";
import useMe from "@/hooks/fetchers/useMe";

export default function useUserProfile() {
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [isLoading, setLoading] = React.useState(false);
  const currentBalance = Number(user?.balance || 0);
  const [setOpen, setData] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );

  const handleOpenModal = (key: string) => {
    setOpen(true);
    setData(key);
  };

  const {
    isActive: isBonus,
    data: promotionHistory,
    isLoading: isPromotionHistoryLoading,
    refresh: refreshPro,
  } = usePromotionHistory();

  const { refresh: refreshMe } = useMe();

  const handleRemovePro = async () => {
    setLoading(true);
    const remove_pro: any = await removePromotion();
    if (!remove_pro?.status) {
      enqueueSnackbar(remove_pro?.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setLoading(false);
    } else {
      enqueueSnackbar(remove_pro?.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      refreshPro();
      refreshMe();
      setLoading(false);
    }
  };

  return {
    user,
    isLoading,
    currentBalance,
    isBonus,
    promotionHistory,
    isPromotionHistoryLoading,
    handleOpenModal,
    handleRemovePro,
  };
}
