"use client";

import { apiPath } from "@/configs/apiPath";
import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import { useEffect } from "react";
import useSWR, { mutate } from "swr";
import { shallow } from "zustand/shallow";

type User = {
  hash: string;
  username: string;
  id: string;
  fullname: string;
  first_name: string;
  last_name: string;
  active: boolean;
  last_login: string;
  bank_code: string;
  account_number: string;
  bank_name: string;
  bank_logo: string;
  group_id: number;
  agent_id: string;
  balance: string;
  bonus: string;
  turnover: string;
  gameusername: string;
  bonususername: string;
  bonus_mode: boolean;
  coins: number;
  created_at: string;
  balance_user_main: string;
  bonus_user_main: string;
  wheel_ticket: boolean;
  mystery_box_ticket: boolean;
  banner_image: null | string;
  banner_active: number;
};

type UserReturn = {
  refresh: () => void;
  getUfaBalance: () => void;
  data: User | null;
  isLoading: boolean;
  isError: any;
};

export default function useMe(): UserReturn {
  const [setUser, getUfaBalance] = useAuthStore((state) => [state.setUser, state.getUfaBalance], shallow);

  const refresh = () => {
    mutate(apiPath.auth.me);
  };

  const { data, error } = useSWR<any>(apiPath.auth.me, fetcherAuth, {
    refreshInterval: 5000,
  });

  useEffect(() => {
    if (data?.data) {
      setUser(data?.data);
    }
  }, [data]);

  return {
    refresh,
    getUfaBalance,
    data: data || null,
    isLoading: !error && !data,
    isError: error,
  };
}
