import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR, { mutate } from "swr";
import { shallow } from "zustand/shallow";

type Promotion = {
  id: string;
  agent_name: string;
  user_id: string;
  promotion_id: string;
  status: boolean;
  amount: string;
  withdraw_min: string;
  withdraw_max: string;
  bonus: string;
  amount_withbonus: string;
  pro_type: string;
  created_at: string;
  updated_at: string;
  promotion: {
    name: string;
    pro_type: number;
    platform: string;
  };
};

type UsePromotionHistoryReturn = {
  isActive: boolean;
  refresh: () => void;
  data: Promotion | null;
  isLoading: boolean;
  isError: any;
};

export default function usePromotionHistory(): UsePromotionHistoryReturn {
  const refresh = () => {
    mutate("/api/v1/promotion/promotions_user");
  };
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<UsePromotionHistoryReturn>(
    isLoggedIn ? `/api/v1/promotion/promotions_user` : null,
    fetcherAuth
  );

  const isActive = data?.data?.id ? true : false;

  return {
    isActive,
    refresh,
    data: data?.data || null,
    isLoading: !error && !data,
    isError: error,
  };
}
