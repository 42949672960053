import { ApiKeyHeaders } from "@/@types/util";
import axios from "axios";
import Cookies from "js-cookie";
import { getApiKeyHeaders } from "./utils";

const http = axios.create({
  timeout: 20000,
});

http.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("accessToken");
    const xApiKeyHeaders: ApiKeyHeaders = getApiKeyHeaders();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    config.headers["X-Api-Key"] = xApiKeyHeaders["X-Api-Key"];
    config.headers["time"] = xApiKeyHeaders.time;
    config.headers["agent"] = xApiKeyHeaders.agent;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
