import { ApiKeyHeaders } from "@/@types/util";
import axios, { AxiosError } from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

export const getIsServer = () => typeof window === "undefined";

export const getApiKeyHeaders = (): ApiKeyHeaders => {
  const isServer = getIsServer();
  const time = Date.now();

  const xApiKey = isServer
    ? process.env.X_API_KEY
    : process.env.NEXT_PUBLIC_X_API_KEY;
  const agent = isServer
    ? process.env.AGENT_CODE
    : process.env.NEXT_PUBLIC_AGENT_CODE;

  if (!xApiKey || !agent) {
    throw new Error("API key or agent code is not set");
  }

  const encryptedApiKey = CryptoJS.MD5(xApiKey + time).toString();

  return { time, "X-Api-Key": encryptedApiKey, agent };
};

export const getProviderConfig = (): string | null => {
  const isServer = getIsServer();

  const isProviderOnly = isServer
    ? process.env.IS_PROVIDER_ONLY
    : process.env.IS_PROVIDER_ONLY;

  return isProviderOnly || null
};

export const fetcherAuth = async <T = any>(url: string): Promise<T> => {
  const accessToken = Cookies.get("accessToken");
  const xApiKeyHeaders: ApiKeyHeaders = getApiKeyHeaders();
  const { data } = await axios.get<T>(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "X-Api-Key": xApiKeyHeaders["X-Api-Key"],
      time: xApiKeyHeaders.time,
      agent: xApiKeyHeaders.agent,
    },
  });
  return data;
};

export const fetcherNonAuth = async <T = any>(url: string): Promise<T> => {
  const xApiKeyHeaders: ApiKeyHeaders = getApiKeyHeaders();
  const { data } = await axios.get<T>(url, {
    headers: {
      "X-Api-Key": xApiKeyHeaders["X-Api-Key"],
      time: xApiKeyHeaders.time,
      agent: xApiKeyHeaders.agent,
    },
  });
  return data;
};

export const shuffleArray = (array = []) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const shuffleLuckyBoxResult = (
  boxes: any,
  boxId = 0,
  winingValue = 0
) => {
  const shuffledList = shuffleArray(boxes.slice());

  const findIndex = shuffledList.findIndex(
    (item: any) => item?.value == winingValue
  );

  [shuffledList[boxId], shuffledList[findIndex]] = [
    shuffledList[findIndex],
    shuffledList[boxId],
  ];

  return shuffledList;
};

export const handleAxiosError = (err: unknown) => {
  if (err && (err as AxiosError).response) {
    const errorResponse = (err as AxiosError).response;
    if (errorResponse) {
      return errorResponse.data;
    }
  }
  throw err;
};
