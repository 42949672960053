import { useAuthStore } from "@/stores/authStore";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";

const usePlayingPopup = () => {
  const [isPlayingPopup, setisPlayingPopup] = useState(false);
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);

  useEffect(() => {
    let isPlayingPopup = localStorage.getItem("isPlayingPopup");
    if (isPlayingPopup && isLoggedIn) {
      setisPlayingPopup(true);
    }
  }, [isLoggedIn]);

  const handleClose = () => {
    setisPlayingPopup(false);
    localStorage.removeItem("isPlayingPopup");
  };

  return {
    isPlayingPopup,
    handleClose,
  };
};

export default usePlayingPopup;
