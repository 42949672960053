import { apiPath } from "@/configs/apiPath";
import http from "@/libs/http";
import { handleAxiosError } from "@/libs/utils";

export const me = async () => {
  try {
    const { data } = await http.get(apiPath.auth.me);
    return data?.data || [];
  } catch (err) {
    return handleAxiosError(err);
  }
};

export const removePromotion = async () => {
  try {
    const { data } = await http.get(apiPath.promotion.remove);
    if (data.status && data.status == true) {
      return {
        status: "success",
        message: data.msg,
        data: data.data,
      };
    } else if (data.status == false && data.errors) {
      return {
        status: "error",
        message: data.errors[0].msg,
        data: data.errors[0],
      };
    } else {
      return {
        status: "error",
        message: data.msg,
        data: data.data,
      };
    }
  } catch (err) {
    return handleAxiosError(err);
  }
};
