import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";

type State = {
  contentKey: string;
  isBlank: boolean;
  title: string;
  open: boolean;
};

type Actions = {
  setOpen: (open: boolean) => void;
  setData: (contentKey: string, title?: string) => void;
  setIsBlank: (isBlank: boolean) => void;
  close: () => void;
};

export const useAppModalStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      contentKey: "wallets",
      isBlank: false,
      title: "",
      open: false,
      setIsBlank: (isBlank) => set((state) => void (state.isBlank = isBlank)),
      close: () => set((state) => void (state.open = false)),
      setOpen: (open) => set((state) => void (state.open = open)),
      setData: (contentKey, title = "") =>
        set(
          (state) =>
            void ((state.contentKey = contentKey), (state.title = title))
        ),
    }))
  ),
  Object.is
);
